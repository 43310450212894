<template>
  <v-dialog v-model="show" :close-on-content-click="false" offset-x>
    <v-card color="grey lighten-4" min-width="350px" flat>
      <validation-observer ref="observer" v-slot="{ invalid }">
        <form>
          <v-card-title>
            <span class="headline">{{ params.formTitle }}</span>
          </v-card-title>
          <v-card-text>
            <v-row class="mt-0">
              <v-col class="pt-0 d-flex justify-space-between" cols="12">
                <div class="d-flex flex-column my-5">
                  <span class="">{{ selectedEvent.name }}</span>
                  <span class="text-caption">{{
                    editedItem.public_id || 'Não criada'
                  }}</span>
                </div>

                <suspensao-programacao-unidade
                  v-if="editavel"
                  ref="suspensao"
                  @cls="close"
                  @att="atualizarListaProgramacoesUnidade"
                  v-bind:programacaoUnidade="editedItem"
                ></suspensao-programacao-unidade>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="3">
                <validation-provider
                  v-slot="{ errors }"
                  name="proprietario"
                  rules="required"
                >
                  <v-autocomplete
                    v-if="!empresaAtual.gate"
                    v-model="editedItem.proprietario"
                    label="Gate/Unidade"
                    :items="gates"
                    :item-text="
                      (item) =>
                        item.business_name +
                        ' - ' +
                        `${item.cnpj != null ? item.cnpj : item.cpf}`
                    "
                    item-value="public_id"
                    return-object
                    hint="Selecione um Gate/Unidade."
                    :error-messages="errors"
                    persistent-hint
                    prepend-icon="mdi-boom-gate"
                    required
                    @change="setFornecedor()"
                    :disabled="
                      editedItem.public_id &&
                      ((editedItem.agendamentos &&
                        editedItem.agendamentos.length > 0) ||
                        (editedItem.programacoes_fornecedor &&
                          editedItem.programacoes_fornecedor.length > 0))
                    "
                  />
                  <v-text-field
                    v-else
                    v-model="empresaAtual.tenant"
                    hint="Selecione um Gate/Unidade."
                    :error-messages="errors"
                    persistent-hint
                    prepend-icon="mdi-boom-gate"
                    :disabled="empresaAtual.gate"
                  />
                </validation-provider>
              </v-col>
              <v-col cols="12" sm="12" md="3">
                <validation-provider
                  v-slot="{ errors }"
                  name="fornecedor"
                  rules="required"
                >
                  <v-autocomplete
                    v-model="editedItem.fornecedor"
                    label="Fornecedor"
                    :items="selectFornecedor"
                    :item-text="
                      (item) =>
                        item.business_name +
                        ' - ' +
                        `${item.cnpj != null ? item.cnpj : item.cpf}`
                    "
                    item-value="public_id"
                    @change="selectedFornecedor()"
                    return-object
                    hint="Selecione um fornecedor."
                    :error-messages="errors"
                    persistent-hint
                    prepend-icon="mdi-equalizer"
                    required
                    :disabled="
                      !empresaAtual.gate ||
                      (editedItem.public_id &&
                        ((editedItem.agendamentos &&
                          editedItem.agendamentos.length > 0) ||
                          (editedItem.programacoes_fornecedor &&
                            editedItem.programacoes_fornecedor.length > 0)))
                    "
                  ></v-autocomplete>
                </validation-provider>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <validation-provider
                  v-slot="{ errors }"
                  name="tipo_operacao"
                  rules="required"
                >
                  <v-autocomplete
                    v-model="editedItem.tipo_operacao"
                    label="Tipo de Operação"
                    :items="tiposOperacao"
                    item-text="descricao"
                    item-value="id"
                    return-object
                    hint="Selecione o tipo de operação."
                    :error-messages="errors"
                    persistent-hint
                    prepend-icon="mdi-clipboard-flow-outline"
                    required
                    :disabled="
                      editedItem.public_id &&
                      ((editedItem.agendamentos &&
                        editedItem.agendamentos.length > 0) ||
                        (editedItem.programacoes_fornecedor &&
                          editedItem.programacoes_fornecedor.length > 0))
                    "
                  />
                </validation-provider>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <validation-provider
                  v-slot="{ errors }"
                  name="terminal"
                  rules="required"
                >
                  <v-autocomplete
                    v-model="editedItem.terminal"
                    label="Terminal"
                    :item-text="
                      (item) =>
                        item.business_name +
                        ' - ' +
                        `${item.cnpj != null ? item.cnpj : item.cpf}`
                    "
                    item-value="public_id"
                    :items="selectTerminal"
                    return-object
                    hint="Selecione um terminal."
                    :error-messages="errors"
                    persistent-hint
                    prepend-icon="mdi-alpha-t-box-outline"
                    required
                    :disabled="
                      editedItem.public_id &&
                      ((editedItem.agendamentos &&
                        editedItem.agendamentos.length > 0) ||
                        (editedItem.programacoes_fornecedor &&
                          editedItem.programacoes_fornecedor.length > 0))
                    "
                  ></v-autocomplete>
                </validation-provider>
              </v-col>
            </v-row>
            <v-divider class="my-5"></v-divider>
            <v-row>
              <v-col class="pt-0" cols="12" sm="4">
                <validation-provider v-slot="{ errors }" name="tipo_controle">
                  <v-autocomplete
                    class="mt-3"
                    v-model="editedItem.tipo_controle"
                    label="Tipo de controle"
                    :error-messages="errors"
                    :items="tiposControle"
                    item-text="descricao"
                    item-value="id"
                    hint="Como os tipos de veículos será utilizado."
                    persistent-hint
                    prepend-icon="mdi-clipboard-alert-outline"
                    return-object
                    :disabled="
                      editedItem.public_id &&
                      ((editedItem.agendamentos &&
                        editedItem.agendamentos.length > 0) ||
                        (editedItem.programacoes_fornecedor &&
                          editedItem.programacoes_fornecedor.length > 0))
                    "
                  ></v-autocomplete>
                </validation-provider>
              </v-col>
              <v-col class="pt-0" cols="12" sm="4">
                <validation-provider v-slot="{ errors }" name="modalidades">
                  <v-autocomplete
                    v-model="editedItem.modalidades"
                    label="Tipos de Veículo"
                    :items="modalidades"
                    :error-messages="errors"
                    item-text="descricao"
                    item-value="id"
                    multiple
                    chips
                    hint="Selecione o tipo de veículo."
                    persistent-hint
                    prepend-icon="mdi-truck-outline"
                    return-object
                    :disabled="
                      editedItem.public_id &&
                      ((editedItem.agendamentos &&
                        editedItem.agendamentos.length > 0) ||
                        (editedItem.programacoes_fornecedor &&
                          editedItem.programacoes_fornecedor.length > 0))
                    "
                  ></v-autocomplete>
                </validation-provider>
              </v-col>
              <v-col class="pt-0" cols="12" sm="4">
                <validation-provider v-slot="{ errors }" name="carrocerias">
                  <v-autocomplete
                    v-model="editedItem.carrocerias"
                    label="Tipos de carroceria"
                    :items="carrocerias"
                    :error-messages="errors"
                    item-text="descricao"
                    item-value="id"
                    multiple
                    chips
                    hint="Selecione o tipo de carroceria."
                    persistent-hint
                    prepend-icon="mdi-truck-outline"
                    return-object
                    :disabled="
                      editedItem.public_id &&
                      ((editedItem.agendamentos &&
                        editedItem.agendamentos.length > 0) ||
                        (editedItem.programacoes_fornecedor &&
                          editedItem.programacoes_fornecedor.length > 0))
                    "
                  ></v-autocomplete>
                </validation-provider>
              </v-col>
              <v-col class="pt-0" cols="12" sm="12">
                <validation-provider v-slot="{ errors }" name="observacao">
                  <v-text-field
                    v-model="editedItem.observacao"
                    :error-messages="errors"
                    label="Observação"
                    prepend-icon="mdi-note-text-outline"
                    hint="Digite uma observação."
                    persistent-hint
                    :disabled="
                      editedItem.public_id &&
                      ((editedItem.agendamentos &&
                        editedItem.agendamentos.length > 0) ||
                        (editedItem.programacoes_fornecedor &&
                          editedItem.programacoes_fornecedor.length > 0))
                    "
                  />
                </validation-provider>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6" lg="3">
                <validation-provider
                  v-slot="{ errors }"
                  name="produto"
                  rules="required"
                >
                  <v-autocomplete
                    v-model="editedItem.produto"
                    label="Produto"
                    item-text="descricao"
                    item-value="id"
                    :items="selectProduto"
                    @change="selectedProduto()"
                    return-object
                    hint="Selecione um produto."
                    :error-messages="errors"
                    persistent-hint
                    prepend-icon="mdi-alpha-p-box-outline"
                    required
                    :disabled="
                      editedItem.public_id &&
                      ((editedItem.agendamentos &&
                        editedItem.agendamentos.length > 0) ||
                        (editedItem.programacoes_fornecedor &&
                          editedItem.programacoes_fornecedor.length > 0))
                    "
                  ></v-autocomplete>
                </validation-provider>
              </v-col>
              <v-col cols="12" md="6" lg="3">
                <validation-provider
                  v-slot="{ errors }"
                  name="sub_produto"
                  rules="required"
                >
                  <v-autocomplete
                    v-model="editedItem.sub_produto"
                    item-text="descricao"
                    label="Sub Produto"
                    item-value="id"
                    :items="selectSubProduto"
                    return-object
                    hint="Selecione um sub produto."
                    :error-messages="errors"
                    persistent-hint
                    prepend-icon="mdi-alpha-p-circle-outline"
                    required
                    :disabled="
                      editedItem.public_id &&
                      ((editedItem.agendamentos &&
                        editedItem.agendamentos.length > 0) ||
                        (editedItem.programacoes_fornecedor &&
                          editedItem.programacoes_fornecedor.length > 0))
                    "
                  ></v-autocomplete>
                </validation-provider>
              </v-col>
              <v-col cols="12" md="6" lg="3">
                <validation-provider
                  v-slot="{ errors }"
                  name="qt_peso"
                  rules="required"
                >
                  <v-col class="pt-0" cols="12"
                    ><v-text-field
                      type="number"
                      v-model="editedItem.qt_peso"
                      :disabled="editedItem.qt_caminhoes != 0"
                      label="Toneladas"
                      hint="Indique o peso em toneladas permitido."
                      :error-messages="errors"
                      persistent-hint
                      prepend-icon="mdi-weight"
                    ></v-text-field>
                  </v-col>
                </validation-provider>
              </v-col>
              <v-col cols="12" md="6" lg="3">
                <validation-provider
                  v-slot="{ errors }"
                  name="qt_caminhoes"
                  rules="required"
                >
                  <v-text-field
                    type="number"
                    v-model="editedItem.qt_caminhoes"
                    :disabled="editedItem.qt_peso != 0"
                    label="Caminhões"
                    hint="Indique a quantidade de caminhões permitida."
                    :error-messages="errors"
                    persistent-hint
                    prepend-icon="mdi-truck-check-outline"
                  ></v-text-field>
                </validation-provider>
              </v-col>
            </v-row>
            <v-divider class="my-5"></v-divider>
            <v-row>
              <v-col cols="12" md="6" lg="3">
                <validation-provider
                  v-slot="{ errors }"
                  name="data_inicio"
                  rules="required"
                >
                  <div class="mt-5 d-flex justify-space-around">
                    <label>Data inicial:</label>
                    <input
                      type="datetime-local"
                      name="datetime"
                      v-model="editedItem.data_inicio"
                      :error-messages="errors"
                      required
                    />
                  </div>
                </validation-provider>
              </v-col>
              <v-col cols="12" md="6" lg="3">
                <validation-provider
                  v-slot="{ errors }"
                  name="data_fim"
                  rules="required"
                >
                  <div class="mt-5 d-flex justify-space-around">
                    <label>Data final:</label>
                    <input
                      type="datetime-local"
                      name="datetime"
                      v-model="editedItem.data_fim"
                      :error-messages="errors"
                      required
                    />
                  </div>
                </validation-provider>
              </v-col>
              <v-col cols="12" md="12" lg="6">
                <label>Replicar:</label>
                <br />
                <v-btn-toggle v-model="replicar" multiple>
                  <v-btn :disabled="!replicavel(DAYS.PRIMEIRO)">
                    {{ texto(DAYS.PRIMEIRO, editedItem) }}
                  </v-btn>
                  <v-btn :disabled="!replicavel(DAYS.SEGUNDO)">
                    {{ texto(DAYS.SEGUNDO, editedItem) }}
                  </v-btn>
                  <v-btn :disabled="!replicavel(DAYS.TERCEIRO)">
                    {{ texto(DAYS.TERCEIRO, editedItem) }}
                  </v-btn>
                  <v-btn :disabled="!replicavel(DAYS.QUARTO)">
                    {{ texto(DAYS.QUARTO, editedItem) }}
                  </v-btn>
                  <v-btn :disabled="!replicavel(DAYS.QUINTO)">
                    {{ texto(DAYS.QUINTO, editedItem) }}
                  </v-btn>
                  <v-btn :disabled="!replicavel(DAYS.SEXTO)">
                    {{ texto(DAYS.SEXTO, editedItem) }}
                  </v-btn>
                  <v-btn :disabled="!replicavel(DAYS.SETIMO)">
                    {{ texto(DAYS.SETIMO, editedItem) }}
                  </v-btn>
                </v-btn-toggle>
              </v-col>
            </v-row>
          </v-card-text>
          <hr class="mx-5" />
          <v-card-actions>
            <v-btn
              color="red darken-1"
              v-if="editavel && notSuspended && canDelete"
              text
              @click="excluirItem()"
              >Deletar</v-btn
            >
            <v-spacer></v-spacer>
            <router-link
              v-if="editavel && notSuspended"
              :to="{
                name: 'NovoAgendamento',
                params: {
                  ...paramsToAgendamento(editedItem),
                },
              }"
            >
              <v-btn
                color="primary"
                v-if="
                  !editavel &&
                  !notSuspended &&
                  qt_peso(editedItem) == 0 &&
                  qt_caminhoes(editedItem) == 0
                "
                small
                >Novo Agendamento</v-btn
              >
            </router-link>
            <v-spacer></v-spacer>
            <v-btn v-if="canUpdate" color="red darken-1" text @click="close"
              >Cancelar</v-btn
            >
            <v-btn
              color="blue darken-1"
              v-if="notSuspended && canUpdate"
              :disabled="
                invalid || (editavel && !editedItem.public_id) || !notSuspended
              "
              text
              @click="save"
              >Salvar</v-btn
            >
          </v-card-actions>
        </form>
      </validation-observer>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'

import { DAYS, TIME_DAYS, date2iso, WEEKDAYS } from '@/mixins/days.js'
import { paramsToAgendamento, contabilizadores } from '@/mixins/cotas.js'
import { parseDate } from '@/mixins/convertion.js'

import Perfil from '@/utils/perfil'
import { BasicPermissions, Sequences } from '@/utils/permissions'

import SuspensaoProgramacaoUnidade from './SuspensaoProgramacaoUnidade.vue'

import ContratoAPI from '@/services/tenants/contratos'

export default {
  props: {
    params: {
      type: Object,
      required: true,
    },
    value: {
      type: Boolean,
      required: true,
    },
  },
  mixins: [contabilizadores, paramsToAgendamento, parseDate],
  components: { SuspensaoProgramacaoUnidade },
  async created() {
    this.DAYS = DAYS
    this.TIME_DAYS = TIME_DAYS
    this.WEEKDAYS = WEEKDAYS
    this.date2iso = date2iso

    await this.atualizarListaTiposControle()
    await this.atualizarListaModalidades()
    await this.atualizarListaCarrocerias()
    await this.getTipoOperacao()

    if (!this.empresaAtual.gate) {
      const { possible_gates } = await ContratoAPI.getPossiveisGates(
        this.empresaAtual.public_id
      )
      this.gates = [...possible_gates]
    } else {
      this.gates = [this.empresaAtual]
      this.editedItem.proprietario = this.empresaAtual
    }
  },
  data() {
    return {
      gates: [],
      selectFornecedor: [],
      selectTerminal: [],
      selectProduto: [],
      selectSubProduto: [],
      editting: false,
      selectedEvent: {
        startTime: null,
        endTime: null,
      },
      editedItem: {
        id: null,
        public_id: null,
        proprietario: null,
        fornecedor: null,
        produto: null,
        sub_produto: null,
        terminal: null,
        data_inicio: '',
        data_fim: '',
        qt_peso: 0,
        qt_caminhoes: 0,
        tipo_operacao: null,
        modalidades: [],
        carrocerias: [],
        tipo_controle: null,
        observacao: '',
        original: null,
        copias: [],
        suspendido: 0,
        suspensoes: [
          {
            descricao: '',
            public_id: '',
            public_id_objeto: '',
            model: 'ProgramacaoUnidade',
            created: '',
            created_by: null,
          },
        ],
      },
      defaultItem: {
        id: null,
        public_id: null,
        proprietario: null,
        fornecedor: null,
        produto: null,
        sub_produto: null,
        terminal: null,
        data_inicio: '',
        data_fim: '',
        qt_peso: 0,
        qt_caminhoes: 0,
        tipo_operacao: null,
        modalidades: [],
        carrocerias: [],
        tipo_controle: null,
        observacao: '',
        original: null,
        copias: [],
        suspendido: 0,
        suspensoes: [
          {
            descricao: '',
            public_id: '',
            public_id_objeto: '',
            model: 'ProgramacaoUnidade',
            created: '',
            created_by: null,
          },
        ],
      },
      replicar: [],
      replicar_possibilidades: Array.from(7).fill(false),
      replicar_public_ids: Array.from(6).fill(null),
    }
  },
  computed: {
    ...mapState('auth', ['empresaAtual']),
    ...mapState('patio', [
      'tiposOperacao',
      'modalidades',
      'carrocerias',
      'tiposControle',
      'terminais',
    ]),
    ...mapState('programacao', ['programacoesUnidadeResumo']),
    ...mapState('produtos', ['produtos']),
    ...mapState('subProdutos', ['subProdutos']),

    show: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    permission() {
      return Sequences.CotasUnidade.toString()
    },

    canUpdate() {
      return this.$canDo(BasicPermissions.EDITAR, this.permission)
    },
    canDelete() {
      return this.$canDo(BasicPermissions.EXCLUIR, this.permission)
    },
    notSuspended() {
      if (this.editedItem.public_id != null) {
        return this.editedItem.suspendido == false
      }
      return true
    },
    editavel() {
      if (this.editedItem.public_id != null) {
        if (new Date(this.editedItem.data_fim) > new Date()) {
          return true
        }
      }
      return false
    },
  },
  watch: {
    show(newValue) {
      if (newValue) {
        this.iniciarEdicao()
      }
    },
  },

  methods: {
    ...mapActions('fornecedores', ['getFornecedoresByParams']),
    ...mapActions('template', [
      'successMessage',
      'errorMessage',
      'setDialogMessage',
    ]),
    ...mapActions('programacao', [
      'addProgramacaoUnidade',
      'editProgramacaoUnidade',
      'getProgramacaoUnidade',
    ]),
    ...mapActions('patio', [
      'deleteProgramacaoUnidade',
      'getTiposControle',
      'getCarrocerias',
      'getModalidades',
      'getTipoOperacao',
    ]),
    ...mapMutations('patio', ['setTerminal']),
    ...mapMutations('produtos', ['setProduto']),
    ...mapMutations('subProdutos', ['setSubProduto']),

    iniciarEdicao() {
      Object.assign(this.editedItem, this.defaultItem)
      const [dia, mes, ano] = this.params.dataSelecionada
        .split(' ')[1]
        .split('/')
      this.selectedEvent.startTime = new Date(ano, mes - 1, dia, 0, 0)
      this.selectedEvent.endTime = new Date(ano, mes - 1, dia, 23, 59, 0)
      this.editedItem.public_id = this.params.public_id || null
      this.editedItem.data_inicio = this.date2iso(this.selectedEvent.startTime)
      this.editedItem.data_fim = this.date2iso(this.selectedEvent.endTime)
      this.editItem(this.editedItem)
    },

    async atualizarListaModalidades() {
      try {
        await this.getModalidades()
      } catch (error) {
        if (error.response && error.response.status == 404) {
          this.errorMessage('Empresa não é um gate.')
        } else {
          this.errorMessage('Ocorreu algum erro inesperado.')
        }
      }
    },
    async atualizarListaCarrocerias() {
      try {
        await this.getCarrocerias()
      } catch (error) {
        if (error.response && error.response.status == 404) {
          this.errorMessage('Empresa não é um gate.')
        } else {
          this.errorMessage('Ocorreu algum erro inesperado.')
        }
      }
    },
    async atualizarListaTiposControle() {
      try {
        await this.getTiposControle()
      } catch (error) {
        if (
          error.response &&
          (error.response.status == 403 || error.response.status == 404)
        ) {
          this.errorMessage('Empresa não é gate para ter fornecedores.')
        } else {
          this.errorMessage(error.response.data)
        }
      }
    },
    async setFornecedor() {
      if (!this.empresaAtual.gate) {
        if (this.editedItem.proprietario) {
          const fornecedores = await this.getFornecedoresByParams({
            empresa: this.editedItem.proprietario,
            params: {
              fornecedor: this.empresaAtual.public_id,
            },
          })
          const fornecedor = fornecedores.find(
            (f) => f.fornecedor_id === this.empresaAtual.public_id
          )
          this.selectFornecedor = [fornecedor]
          this.editedItem.fornecedor = fornecedor
        }
      } else {
        await this.atualizarListaFornecedores()
      }
      this.selectedFornecedor()
    },

    async atualizarListaFornecedores() {
      try {
        const fornecedores = await this.getFornecedoresByParams({
          empresa: this.editedItem.proprietario,
        })
        this.selectFornecedor = [...fornecedores]
      } catch (error) {
        if (
          error.response &&
          (error.response.status == 403 || error.response.status == 404)
        ) {
          this.errorMessage('Empresa não é gate para ter fornecedores.')
        } else {
          this.errorMessage(error.response.data)
        }
      }
    },

    async selectedFornecedor() {
      if (this.editedItem.fornecedor != null) {
        if (this.editedItem.fornecedor.public_id != null) {
          this.setProduto(this.editedItem.fornecedor.produtos)
          this.selectProduto = [
            { descricao: 'Nenhum', id: null },
            ...this.produtos,
          ]
          this.setTerminal(
            await ContratoAPI.getContratos(
              this.editedItem.fornecedor.fornecedor_id,
              {
                perfil: Perfil.Terminal,
              }
            )
          )
          this.selectTerminal = [...this.terminais]
        } else {
          this.selectProduto = [{ descricao: 'Nenhum', id: null }]
          this.selectTerminal = []
          this.editedItem.produto = null
          this.editedItem.sub_produto = null
          this.editedItem.terminal = null
        }
      } else {
        this.selectProduto = [{ descricao: 'Nenhum', id: null }]
        this.selectTerminal = []
        this.editedItem.produto = null
        this.editedItem.sub_produto = null
        this.editedItem.terminal = null
      }
      this.selectedProduto()
    },
    selectedProduto() {
      if (this.editedItem.produto != null) {
        if (this.editedItem.produto.id != null) {
          this.setSubProduto(this.editedItem.produto.subprodutos)
          this.selectSubProduto = [
            { descricao: 'Nenhum', id: null },
            ...this.subProdutos,
          ]
        } else {
          this.selectSubProduto = [{ descricao: 'Nenhum', id: null }]
          this.editedItem.sub_produto = null
        }
      } else {
        this.selectSubProduto = [{ descricao: 'Nenhum', id: null }]
        this.editedItem.sub_produto = null
      }
    },

    replicavel(dia) {
      return this.replicar_possibilidades[dia]
    },
    texto(ordem, programacao) {
      let dia = new Date(
        programacao.original
          ? programacao.original.data_inicio
          : programacao.data_inicio
      )
      let result = dia.setDate(dia.getDate() + ordem)

      return WEEKDAYS[new Date(result).getDay()]
    },
    async editItem(item) {
      if (item.public_id) {
        item = await this.getProgramacaoUnidade(item.public_id)
      }
      this.replicar = []
      Object.assign(this.editedItem, item)
      if (this.editedItem.suspensoes.length == 0) {
        this.editedItem.suspensoes = this.defaultItem.suspensoes
      }
      if (this.empresaAtual.gate) {
        this.editedItem.proprietario = this.empresaAtual
      }
      this.setFornecedor()
      this.editReplicas()
      // this.editedItem.data_inicio = item.public_id ? this.date2iso(new Date(this.editedItem.data_inicio)) : this.date2iso(this.selectedEvent.startTime)
      // this.editedItem.data_fim = item.public_id ? this.date2iso(new Date(this.editedItem.data_fim)) : this.date2iso(this.selectedEvent.endTime)
    },
    editReplicas() {
      if (this.editedItem.public_id == null) {
        this.replicar_possibilidades = [false, ...Array(6).fill(true)]
      } else {
        this.replicar_possibilidades = Array(7).fill(false)
        let copias
        if (this.editedItem.original != null) {
          copias = this.editedItem.original.copias
          if (
            copias.findIndex(
              (element) =>
                element.public_id == this.editedItem.original.public_id
            ) == -1
          ) {
            let original = { ...this.editedItem.original }
            delete original.copias
            copias.push(original)
          }
          let index = copias.findIndex(
            (element) => element.public_id == this.editedItem.public_id
          )
          if (index > -1) {
            copias.splice(index, 1)
          }
        } else {
          copias = this.editedItem.copias
        }

        let original = this.editedItem.original
          ? this.editedItem.original
          : this.editedItem
        let original_data = new Date(original.data_inicio)

        copias.forEach(async (copia) => {
          let data = new Date(copia.data_inicio)
          if (data > new Date()) {
            let posicao =
              (new Date(this.parseDate(data)) -
                new Date(this.parseDate(original_data))) /
              (1000 * 60 * 60 * 24)
            this.replicar_possibilidades[posicao] = true
            this.replicar_public_ids[posicao] = copia.public_id
          }
        })
      }
    },

    async editarReplicas(edited) {
      let original = edited.original ? edited.original : edited
      await Promise.all(
        this.replicar.map(async (dia) => {
          try {
            const data_inicio = original.copias.find(
              (element) => element.public_id == this.replicar_public_ids[dia]
            ).data_inicio
            // Pegar apenas as datas e fazer um merge com a hora
            const data_fim = original.copias.find(
              (element) => element.public_id == this.replicar_public_ids[dia]
            ).data_fim
            const item = {
              ...edited,
              public_id: this.replicar_public_ids[dia],
              data_inicio,
              data_fim,
            }
            await this.editProgramacaoUnidade(item)
          } catch (error) {
            if (
              error.response &&
              (error.response.status == 403 || error.response.status == 404)
            ) {
              this.errorMessage(
                'Empresa atual não pode cadastrar/editar programações.'
              )
            } else {
              this.errorMessage(error.response ? error.response.data : error)
            }
          }
        })
      )
    },

    async cadastrarReplicas(original) {
      await Promise.all(
        this.replicar.map(async (element) => {
          try {
            let item = { ...this.editedItem }
            item.original = original
            let start = new Date(item.data_inicio)
            let end = new Date(item.data_fim)
            if (element > 0) {
              item.data_inicio = this.date2iso(
                new Date(start.getTime() + TIME_DAYS(element))
              )
              item.data_fim = this.date2iso(
                new Date(end.getTime() + TIME_DAYS(element))
              )
              await this.addProgramacaoUnidade(item)
            }
          } catch (error) {
            if (
              error.response &&
              (error.response.status == 403 || error.response.status == 404)
            ) {
              this.errorMessage(
                'Empresa atual não pode cadastrar/editar programações.'
              )
            } else {
              this.errorMessage(error.response ? error.response.data : error)
            }
          }
        })
      )
    },
    close() {
      this.editting = false
      this.show = false
      setTimeout(() => {
        this.selectedEvent.item = { ...this.editedItem }
        Object.assign(this.editedItem, this.defaultItem)
      }, 100)
    },

    async excluirItem() {
      if (this.editedItem.suspendido) {
        this.errorMessage('Não é possível deletar programações suspendidas.')
        return 0
      }
      if (this.editedItem.public_id != null) {
        console.log('aaaaa')
        console.log({ ...this.editedItem })
        try {
          await this.deleteProgramacaoUnidade(this.editedItem)
        } catch (error) {
          if (
            error.response &&
            (error.response.status == 403 || error.response.status == 404)
          ) {
            this.errorMessage(
              'Empresa atual não pode cadastrar/editar programações.'
            )
          } else {
            this.errorMessage(
              'Erro desconhecido. Tente novamente ou contacte o suporte.'
            )
          }
        }
      }
      this.close()
    },

    async save() {
      try {
        let original = null
        if (this.editedItem.public_id != null) {
          original = await this.editProgramacaoUnidade(this.editedItem)
          if (this.replicar.length > 0) {
            await this.editarReplicas(original)
            // await this.editarReplicas(this.editedItem)
          }
        } else {
          original = await this.addProgramacaoUnidade(this.editedItem)
          if (this.replicar.length > 0) {
            await this.cadastrarReplicas(original)
          }
        }
        this.$emit('atualizar')
        this.close()
      } catch (error) {
        if (
          error.response &&
          (error.response.status == 403 || error.response.status == 404)
        ) {
          this.errorMessage(
            'Empresa atual não pode cadastrar/editar programações.'
          )
        } else {
          this.errorMessage(
            error.response ? error.response.data : error.message
          )
        }
      }
    },
  },
}
</script>
