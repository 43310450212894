var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex justify-space-between mt-3 mb-6"},[_c('v-spacer'),_c('span',{staticClass:"mt-3 subtitle-1"},[_vm._v("Filtros")]),_c('v-spacer')],1),_c('div',[_c('v-row',{staticClass:"mt-0"},[(!this.empresaAtual.gate)?_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12"}},[_c('v-autocomplete',{attrs:{"label":"Gate","items":_vm.selectGate,"item-text":function (item) { return item.business_name +
              ' - ' +
              "" + (item.cnpj != null ? item.cnpj : item.cpf); },"item-value":"public_id","return-object":""},on:{"change":function($event){return _vm.selectedGate()}},model:{value:(_vm.params.gate),callback:function ($$v) {_vm.$set(_vm.params, "gate", $$v)},expression:"params.gate"}})],1):_vm._e()],1),_c('v-row',{staticClass:"mt-0"},[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12"}},[_c('v-autocomplete',{attrs:{"label":"Tipo de operação","items":_vm.selectTipoOperacao,"item-text":"descricao","item-value":"id","return-object":""},model:{value:(_vm.params.tipo_operacao),callback:function ($$v) {_vm.$set(_vm.params, "tipo_operacao", $$v)},expression:"params.tipo_operacao"}})],1)],1),_c('v-row',{staticClass:"mt-0"},[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12"}},[_c('v-autocomplete',{attrs:{"label":"Fornecedor","items":_vm.selectFornecedor,"item-text":function (item) { return item.business_name +
              ' - ' +
              "" + (item.cnpj != null ? item.cnpj : item.cpf); },"item-value":"public_id","return-object":""},on:{"change":function($event){return _vm.selectedFornecedor()}},model:{value:(_vm.params.fornecedor),callback:function ($$v) {_vm.$set(_vm.params, "fornecedor", $$v)},expression:"params.fornecedor"}})],1)],1),_c('v-row',{staticClass:"mt-0"},[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12"}},[_c('v-autocomplete',{attrs:{"label":"Terminal","item-text":function (item) { return item.business_name +
              ' - ' +
              "" + (item.cnpj != null ? item.cnpj : item.cpf); },"item-value":"public_id","items":_vm.selectTerminal,"return-object":""},model:{value:(_vm.params.terminal),callback:function ($$v) {_vm.$set(_vm.params, "terminal", $$v)},expression:"params.terminal"}})],1)],1),_c('v-row',{staticClass:"mt-0"},[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12"}},[_c('v-autocomplete',{attrs:{"label":"Produto","item-text":"descricao","item-value":"id","items":_vm.selectProduto,"return-object":""},on:{"change":function($event){return _vm.selectedProduto()}},model:{value:(_vm.params.produto),callback:function ($$v) {_vm.$set(_vm.params, "produto", $$v)},expression:"params.produto"}})],1)],1),_c('v-row',{staticClass:"mt-0"},[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12"}},[_c('v-autocomplete',{attrs:{"item-text":"descricao","label":"Sub Produto","item-value":"id","items":_vm.selectSubProduto,"return-object":""},model:{value:(_vm.params.sub_produto),callback:function ($$v) {_vm.$set(_vm.params, "sub_produto", $$v)},expression:"params.sub_produto"}})],1)],1),_c('v-row',{staticClass:"mt-0"},[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Semana","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.params.data_inicio),callback:function ($$v) {_vm.$set(_vm.params, "data_inicio", $$v)},expression:"params.data_inicio"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.showMenuDate),callback:function ($$v) {_vm.showMenuDate=$$v},expression:"showMenuDate"}},[_c('v-date-picker',{attrs:{"scrollable":"","no-title":"","show-adjacent-months":""},on:{"input":function($event){_vm.showMenuDate = false}},model:{value:(_vm.params.data_inicio),callback:function ($$v) {_vm.$set(_vm.params, "data_inicio", $$v)},expression:"params.data_inicio"}})],1)],1)],1),_c('div',{staticClass:"d-flex justify-center"},[_c('v-btn',{attrs:{"color":"primary","small":""},on:{"click":function($event){return _vm.$emit('pesquisar', _vm.params)}}},[_vm._v(" Pesquisar ")])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }