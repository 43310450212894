<template>
  <v-card>
    <v-card-title class="d-flex justify-space-between">
      <span>Filtros</span>
      <div>
        <v-btn
          class="mx-2"
          title="Configurar filtros"
          color="primary"
          @click="$emit('showFilters')"
        >
          <v-icon>mdi-cog</v-icon>
        </v-btn>
      </div>
    </v-card-title>
  </v-card>
</template>

<script>

export default {
  name: 'FiltroProgramacaoUnidade',

}
</script>
