<template>
  <section>
    <v-overlay :value="carregandoDados">
      <v-progress-circular indeterminate size="64" />
    </v-overlay>

    <calendario-programacao
      :programacoes="programacoesUnidadeResumo"
      @coletarClickTabela="coletarClickTabela"
      @detalhesDistribuicao="detalhesDistribuicao"
    />
    <form-programacao-unidade
      v-model="abrirFormProgramacaoUnidade"
      :params="paramsModalEdicaoProgramacao"
      @atualizar="$emit('atualizar')"
    />
    <v-dialog v-model="detalhar">
      <detalhes-programacao
        :detalhes="detalhes"
        :headers="headersDetalhes"
        :title="title"
        :download="download"
        @close="() => (detalhar = false)"
      />
    </v-dialog>
  </section>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import FormProgramacaoUnidade from './FormProgramacaoUnidade'
import DetalhesProgramacao from '@/components/patio/Programacao/Detalhes/DetalhesProgramacao'
import CalendarioProgramacao from '../CalendarioProgramacao.vue'

import { contabilizadores, pesquisa } from '@/mixins/cotas.js'

export default {
  name: 'CalendarioProgramacaoUnidade',
  mixins: [contabilizadores, pesquisa],
  components: {
    FormProgramacaoUnidade,
    DetalhesProgramacao,
    CalendarioProgramacao,
  },

  data() {
    return {
      carregandoDados: false,
      abrirFormProgramacaoUnidade: false,
      paramsModalEdicaoProgramacao: {
        formTitle: 'Nova programação',
        dataSelecionada: '',
        public_id: '',
      },
      detalhes: [],
      title: {},
      download: {
        type: '',
        public_id: '',
      },
      detalhar: false,
      headersDetalhes: [
      {
          text: 'Veículo',
          value: 'veiculo',
        },
        { 
          text: 'Status', 
          value: 'status' 
        },
        {
          text: 'Data da Cota',
          value: 'data_cota',
        },
        {
          text: 'Triado Em',
          value: 'triado_em',
        },
        {
          text: 'Liberado Em',
          value: 'saida_do_patio',
        },
        {
          text: 'Tempo em patio',
          value: 'tempo_em_patio',
        },
        {
          text: 'Finalizado Em',
          value: 'chegada_terminal',
        },
        {
          text: 'Cliente',
          value: 'cliente',
        },
        {
          text: 'Produto',
          value: 'produto',
        },
        {
          text: 'Sub Produto',
          value: 'sub_produto',
        },
        {
          text: 'Nota fiscal',
          value: 'num_nota_fiscal',
        },
        {
          text: 'Quantidade (KG)',
          value: 'quantidade',
        },
        {
          text: 'Embalagem',
          value: 'embalagem',
        },
        {
          text: 'Modalidade',
          value: 'modalidade',
        },
        {
          text: 'Eixos',
          value: 'eixos',
        },
      ],
    }
  },
  computed: {
    ...mapState('programacao', ['params', 'programacoesUnidadeResumo']),
  },
  methods: {
    ...mapActions('template', ['errorMessage']),
    ...mapActions('programacao', [
      'getProgramacoesUnidadeResumo',
      'getProgramacaoDetalhe',
    ]),
    extractFiltersFromParams(params = null) {
      params = params == null ? this.params : params
      const filtros = this.pesquisa(params)
      if (!filtros.data_inicio) {
        filtros.data_inicio = new Date(
          new Date(new Date().setHours(0, 0, 0, 0)).setDate(
            new Date().getDate() - new Date().getDay()
          )
        )
          .toISOString()
          .split('T')[0]
      }
      return filtros
    },
    async atualizarResumoProgramacaoUnidade(params = null) {
      try {
        const filtros = this.extractFiltersFromParams(params)
        this.carregandoDados = true
        await this.getProgramacoesUnidadeResumo(filtros)
        this.carregandoDados = false
      } catch (error) {
        error.response &&
        (error.response.status == 403 || error.response.status == 404)
          ? this.errorMessage(
              'Empresa não possui acesso às programações de unidade.'
            )
          : this.errorMessage(
              'Erro ao atualizar resumo. Tente novamente ou contacte o suporte.'
            )
      }
    },
    async detalhesDistribuicao(item) {
      try {
        this.carregandoDados = true
        const data = await this.getProgramacaoDetalhe({
          type: 'Unidade',
          public_id: item.PUBLIC_ID,
        })
        this.detalhes = data.agendamentos
        this.title = data.title
        this.download.type = 'Unidade'
        this.download.public_id = item.public_id
        this.detalhar = true
        this.carregandoDados = false
      } catch (error) {
        console.log(error)
        this.errorMessage(
          'Erro ao buscar detalhes. Tente novamente ou contacte o suporte.'
        )
      } finally {
        this.carregandoDados = false
      }
    },
    coletarClickTabela(col, item) {
      if (
        typeof item === 'object' &&
        item['PUBLIC_ID'] &&
        item['PUBLIC_ID'] !== ''
      ) {
        this.paramsModalEdicaoProgramacao.formTitle = 'Editar programação'
        this.paramsModalEdicaoProgramacao.dataSelecionada = col
        this.paramsModalEdicaoProgramacao.public_id = item['PUBLIC_ID']
        this.abrirFormProgramacaoUnidade = true
      } else if (col !== 'info') {
        this.paramsModalEdicaoProgramacao.formTitle = 'Nova programação'
        this.paramsModalEdicaoProgramacao.public_id = null
        this.paramsModalEdicaoProgramacao.dataSelecionada = col
        this.abrirFormProgramacaoUnidade = true
      }
    },
  },
}
</script>

<style scoped></style>
